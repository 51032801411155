import React, { useState } from 'react';
import { useEffect } from 'react';
import './SearchBar.css'


const MyApp = () => {
  const [search, setSearch] = useState('');
  const [apiResult, setApiResult] = useState(null);

  let filteredProducts = ['Supper Hero shirt', 'Men formal shoes', 'New born dress', 'Classic watch',
    'Indian Jersy', 'Office wear for women', 'Party handbag', 'perfume', 'tapered jeans', 
    'checked shirt'
  ]

  const handleOnChange = (e) => {
    setSearch(e.target.value);
  }

  const handleOnSuggestionClick = (e) => {
    console.log('product', e.target.innerText)

    setSearch(e.target.innerText);
    apiCall(e.target.innerText)
  }

  const apiCall = q => {
    let query = {
      query: q
    };

    let requestOptions = {
      method: 'POST',
      body: JSON.stringify(query),
      redirect: 'follow'
    };

    fetch("http://43.204.216.178/api/search", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setApiResult(result)
      })
      .catch(error => console.log('error', error));
  }

  const handleOnClick = (e) => {
    e.preventDefault();

    if (search.length == 0) {
      prompt("Empty search?")
      return
    }

    apiCall(search)
  }

  return (
    <div className="container">
      <Search search={search} handleOnClick={handleOnClick} onChange={handleOnChange} />
      <div className="results-wrap">
        <ul>
          {filteredProducts.map((product) => {
            return <li key={product} className='list-item' onClick={handleOnSuggestionClick}><a href='#'>{product}</a></li>
          })}
        </ul>
      </div>

      {apiResult && <List Results={apiResult.result} />}

    </div>
  )
}

const Search = (props) => {
  return (
    <div>
      <form className="container_search" onSubmit={props.handleOnClick}>
        <label for='search' className='search-toggle-label'>
          <svg className="search-style" viewBox="0 0 512 512" width="15" title="search">
            <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" />
          </svg></label>
        <input id="search" type="search" className="search" value={props.search} onChange={props.onChange} placeholder="Enter Search" />
        <button type="submit" onClick={props.handleOnClick}>Search</button>
      </form>
    </div>
  )
}

const Results = (props) => {
  return (
    <div>
      {props.Results.length}&nbsp;{props.Results.length > 1 ? "results" : "result"}
    </div>
  )
}

const List = (props) => {

  return (
    <div className="container_list">
      {props.Results.map((data, i) => {
        return (
          <div className="container_list_item" key={i}>
            <div><img src={data.path} alt={data.path} /></div>
            {/* <div>{data.name}</div> */}
          </div>
        )
      })}
    </div>
  )
}

export default MyApp